import React from 'react';
import { useSelector } from 'react-redux';

function OrderTabs({ onChangeTab }) {
  const {
    selectedTab,
    receivedOrdersCount,
    startedOrdersCount,
    almostReadyOrdersCount,
    pickupOrdersCount,
    deliveryOrdersCount,
    completeOrdersCount,
  } = useSelector((state: any) => state.dashboard);
  return (
    <ul className="tabs" data-tabs>
      <li className={selectedTab === 'POTENTIAL_ORDER' ? 'active' : ''}>
        <a
          data-tab
          href="#potential"
          className="active"
          onClick={() => onChangeTab('POTENTIAL_ORDER')}
        >
          {' '}
          Potential{' '}
        </a>
      </li>
      <li className={selectedTab === 'ORDER_RECEIVED' ? 'active' : ''}>
        <a
          data-tab
          href="#received"
          className={(receivedOrdersCount && '-hasnotif') || ''}
          data-count={receivedOrdersCount}
          onClick={() => onChangeTab('ORDER_RECEIVED')}
        >
          {' '}
          Received{' '}
        </a>
      </li>
      <li className={selectedTab === 'ORDER_STARTED' ? 'active' : ''}>
        <a
          data-tab
          href="#started"
          className={(startedOrdersCount && '-hasnotif') || ''}
          data-count={startedOrdersCount}
          onClick={() => onChangeTab('ORDER_STARTED')}
        >
          {' '}
          Started{' '}
        </a>
      </li>
      <li
        className={
          selectedTab === 'ORDER_READY_IN_FIVE_MINUTES' ? 'active' : ''
        }
      >
        <a
          data-tab
          href="#ready"
          className={(almostReadyOrdersCount && '-hasnotif') || ''}
          data-count={almostReadyOrdersCount}
          onClick={() => onChangeTab('ORDER_READY_IN_FIVE_MINUTES')}
        >
          {' '}
          Ready in 5 mins{' '}
        </a>
      </li>
      <li className={selectedTab === 'ORDER_READY_FOR_PICKUP' ? 'active' : ''}>
        <a
          data-tab
          href="#pickup"
          className={(pickupOrdersCount && '-hasnotif') || ''}
          data-count={pickupOrdersCount}
          onClick={() => onChangeTab('ORDER_READY_FOR_PICKUP')}
        >
          {' '}
          Ready for Pickup{' '}
        </a>
      </li>
      <li className={selectedTab === 'ORDER_OUT_FOR_DELIVERY' ? 'active' : ''}>
        <a
          data-tab
          href="#delivery"
          className={(deliveryOrdersCount && '-hasnotif') || ''}
          data-count={deliveryOrdersCount}
          onClick={() => onChangeTab('ORDER_OUT_FOR_DELIVERY')}
        >
          Out for Delivery
        </a>
      </li>
      <li className={selectedTab === 'ORDER_COMPLETE' ? 'active' : ''}>
        <a
          data-tab
          href="#complete"
          onClick={() => onChangeTab('ORDER_COMPLETE')}
        >
          {' '}
          Complete{' '}
        </a>
      </li>
    </ul>
  );
}

export default OrderTabs;
