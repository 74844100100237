// Dashboard Actions
export const ADD_ORDERS_LIST = 'ADD_ORDERS_LIST';
export const UPDATE_USER = 'UPDATE_USER';
export const SELECT_ORDER = 'SELECT_ORDER';
export const SELECT_TAB = 'SELECT_TAB';
export const UPDATE_SPECIFIC_LIST = 'UPDATE_SPECIFIC_LIST';
export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT';
export const UPDATE_ORDER_REQUEST_READ_COUNT =
  'UPDATE_ORDER_REQUEST_READ_COUNT';
export const REMOVE_NOTIF_COUNT = 'REMOVE_NOTIF_COUNT';
export const UPDATE_BRANCH_ID = 'UPDATE_BRANCH_ID';
export const ADD_NEW_ORDER_TO_LIST = 'ADD_NEW_ORDER_TO_LIST';
export const UPDATE_ORDER_TO_ANOTHER_STATE = 'UPDATE_ORDER_TO_ANOTHER_STATE';
export const UPDATE_BRANCH_DETAILS = 'UPDATE_BRANCH_DETAILS';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDERS = 'DELETE_ORDERS';
export const ADD_BULK_DELETE_ORDERS_LIST = 'ADD_BULK_DELETE_ORDERS_LIST';
export const ADD_BULK_LIST_ORDERS = 'ADD_BULK_LIST_ORDERS';
export const REMOVE_BULK_LIST_ORDERS = 'REMOVE_BULK_LIST_ORDERS';
export const REMOVE_BULK_DELETE_ORDERS_LIST = 'REMOVE_BULK_DELETE_ORDERS_LIST';
export const TOGGLE_SELECT_ALL_POTENTIAL_ORDERS =
  'TOGGLE_SELECT_ALL_POTENTIAL_ORDERS';
export const ADD_TOAST_ID = 'ADD_TOAST_ID';
export const REMOVE_TOAST_ID = 'REMOVE_TOAST_ID';
export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const UPDATE_ARCHIVE_ORDERS = 'UPDATE_ARCHIVE_ORDERS';

// Archive Orders
export const ADD_BULK_ARCHIVE_ORDERS_LIST = 'ADD_BULK_ARCHIVE_ORDERS_LIST';
export const REMOVE_BULK_ARCHIVE_ORDERS_LIST =
  'REMOVE_BULK_ARCHIVE_ORDERS_LIST';
export const TOGGLE_SELECT_ALL_COMPLETED_ORDERS =
  'TOGGLE_SELECT_ALL_COMPLETED_ORDERS';

// App Actions
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_TEXT = 'SET_LOADING_TEXT';
