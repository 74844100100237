import OrderStatus from './order-status';

const orderIndexes = [
  'POTENTIAL_ORDER',
  'ORDER_RECEIVED',
  'ORDER_STARTED',
  'ORDER_READY_IN_FIVE_MINUTES',
  'ORDER_READY_FOR_PICKUP',
  'ORDER_OUT_FOR_DELIVERY',
  'ORDER_COMPLETE',
];

export const getOrderStatus = (status: string) => {
  switch (status) {
    case 'POTENTIAL_ORDER':
      return OrderStatus.POTENTIAL_ORDER;
    case 'ORDER_STARTED':
      return OrderStatus.ORDER_STARTED;
    case 'ORDER_RECEIVED':
      return OrderStatus.ORDER_RECEIVED;
    case 'ORDER_READY_IN_FIVE_MINUTES':
      return OrderStatus.ORDER_READY_IN_FIVE_MINUTES;
    case 'ORDER_READY_FOR_PICKUP':
      return OrderStatus.ORDER_READY_FOR_PICKUP;
    case 'ORDER_OUT_FOR_DELIVERY':
      return OrderStatus.ORDER_OUT_FOR_DELIVERY;
    case 'ORDER_COMPLETE':
      return OrderStatus.ORDER_COMPLETE;
    default:
      return OrderStatus.POTENTIAL_ORDER;
  }
};

export const getPreviosOrderStatus = (status: string) => {
  switch (status) {
    case 'POTENTIAL_ORDER':
      return OrderStatus.POTENTIAL_ORDER;
    case 'ORDER_RECEIVED':
      return OrderStatus.POTENTIAL_ORDER;
    case 'ORDER_STARTED':
      return OrderStatus.ORDER_RECEIVED;
    case 'ORDER_READY_IN_FIVE_MINUTES':
      return OrderStatus.ORDER_STARTED;
    case 'ORDER_READY_FOR_PICKUP':
      return OrderStatus.ORDER_READY_IN_FIVE_MINUTES;
    case 'ORDER_OUT_FOR_DELIVERY':
      return OrderStatus.ORDER_READY_IN_FIVE_MINUTES;
    case 'ORDER_COMPLETE':
      return OrderStatus.ORDER_READY_FOR_PICKUP;
    default:
      return OrderStatus.POTENTIAL_ORDER;
  }
};

export const getOrderRowCss = (status: string) => {
  switch (status) {
    case 'POTENTIAL_ORDER':
      return 'potential';
    case 'ORDER_RECEIVED':
      return 'received';
    case 'ORDER_STARTED':
      return 'started';
    case 'ORDER_READY_IN_FIVE_MINUTES':
      return 'almost';
    case 'ORDER_READY_FOR_PICKUP':
      return 'pickup';
    case 'ORDER_OUT_FOR_DELIVERY':
      return 'delivery';
    case 'ORDER_COMPLETE':
      return 'complete';
    default:
      return 'potential';
  }
};

export const getNextOrderStatus = (status: string) => {
  switch (status) {
    case 'POTENTIAL_ORDER':
      return 'ORDER_RECEIVED';
    case 'ORDER_RECEIVED':
      return 'ORDER_STARTED';
    case 'ORDER_STARTED':
      return 'ORDER_READY_IN_FIVE_MINUTES';
    case 'ORDER_READY_IN_FIVE_MINUTES':
      return 'ORDER_READY_FOR_PICKUP';
    case 'ORDER_READY_FOR_PICKUP':
      return 'ORDER_COMPLETE';
    case 'ORDER_OUT_FOR_DELIVERY':
      return 'ORDER_COMPLETE';
    case 'ORDER_COMPLETE':
      return 'ORDER_COMPLETE';
    default:
      return 'ORDER_RECEIVED';
  }
};

export const getOrderSvg = (status: string) => {
  switch (status) {
    case 'POTENTIAL_ORDER':
      return 'images/potential-icon.svg';
    case 'ORDER_RECEIVED':
      return 'images/received-icon.svg';
    case 'ORDER_STARTED':
      return 'images/started-icon.svg';
    case 'ORDER_READY_IN_FIVE_MINUTES':
      return 'images/almost-icon.svg';
    case 'ORDER_READY_FOR_PICKUP':
      return 'images/pickup-icon.svg';
    case 'ORDER_OUT_FOR_DELIVERY':
      return 'images/delivery-icon.svg';
    case 'ORDER_COMPLETE':
      return 'images/complete-icon.svg';
    default:
      return 'images/potential-icon.svg';
  }
};

export const getOrderStatusImage = (status: string) => {
  switch (status) {
    case 'POTENTIAL_ORDER':
      return '';
    case 'ORDER_RECEIVED':
      return 'received-image';
    case 'ORDER_STARTED':
      return 'started-image';
    case 'ORDER_READY_IN_FIVE_MINUTES':
      return 'almost-ready-image';
    case 'ORDER_READY_FOR_PICKUP':
      return 'pickup-image';
    case 'ORDER_OUT_FOR_DELIVERY':
      return 'pickup-image';
    case 'ORDER_COMPLETE':
      return 'complete-image';
    default:
      return '';
  }
};

export const getOrderButtonClass = (order: any, index: number) => {
  const { orderStatus } = order;
  const orderStatusIndex = orderIndexes.findIndex(
    (orderIndex) => orderIndex === orderStatus,
  );
  return orderStatusIndex >= index ? '-active' : '';
};
