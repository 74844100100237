import React from 'react';
import ReactPlayer from 'react-player';
import './landingPage.css';

function LandingPage() {
  return (
    <>
      <div className="mx-auto landing-page">
        <div className="w-full d-block text-center">
          <div id="header">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="login-button">
            <button class="bg-purple-500 hover:bg-indigo-500 text-white font-bold py-2 px-4 my-5 mx-4 rounded-full float-right">
              <a href="/login">Register / Log In</a>
            </button>
          </div>
          <h1 className="text-4xl lg:text-6xl md:text-5xl py-5 text-white font-bold font-sans">
            Get The New Free Order Tracker!
          </h1>
          <div className="flex flex-col sm:flex-row container mx-auto text-center downloads">
            <div className="lg:w-1/2 sm:mx-3 sm:py-3 my-3 lg:my-3 lg:py-3">
              <button class="bg-purple-500 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded-full float-none lg:float-right md:float-right">
                <a href="https://apps.apple.com/us/app/apptech-gateway-usa-llc/id1458978293" target="_blank"><img src="/images/apple-white.svg"></img>APP STORE</a>
              </button>
            </div>
            <div className="lg:w-1/2 sm:mx-3 sm:py-3 my-3 lg:my-3 lg:py-3">
              <button class="bg-purple-500 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded-full float-none lg:float-left md:float-left">
                <a href="https://play.google.com/store/apps/details?id=com.apptechgateway.AppTech&hl=en" target="_blank"><img src="/images/play-store-white.svg"></img>GOOGLE PLAY</a>
              </button>
            </div>
          </div>
          <img className="mx-auto py-5 phones" src="/images/Phones.png" />
          <div className="triangle-bl-purpure"></div>
        </div>
        <div className="purple-line-three"><br/></div>
        <div className="text-center py-0 text-3xl lg:text-4xl font-sans font-semibold mt-0 lg:mt-0 sm:mt-5 sm:py-5">
          <h2 className="purple text-white lg:text-purple-600 md:text-purple-600">Never Lose Track Of Your Order!</h2>
        </div>
        <div className=" flex flex-col lg:flex-row container mx-auto text-center seamless_process">
          <div className="lg:w-1/4 shadow-lg relative mx-3 my-3 py-3 rounded bg-white text-left">
            <img
              className="mx-auto icon my-3"
              src="/images/download.svg"
              alt=""
            />
            <h5 className="py-3 px-5 font-semibold text-center">
              Download & Register
            </h5>
            <p className="py-3 px-5 text-center">
              <p>Download the Free App from the App Store & Play Store</p>
              <p>Register & Sign In</p>
              <p>Click on Order Tracker</p>
            </p>
            <div className="circle absolute shadow-lg mx-3 my-3 py-3 rounded">
              <img className="arrow" src="/images/arrow.png" alt="arrow" />
            </div>
          </div>
          <div className="lg:w-1/4 shadow-lg relative mx-3 my-3 py-3 rounded bg-white text-left">
            <img
              className="mx-auto icon my-3"
              src="/images/browse-restaurant.svg"
              alt=""
            />
            <h5 className="py-3 px-5 font-semibold text-center text-center">
              Choose A Business
            </h5>
            <p className="py-3 px-5 text-center">
              <p>Adjust Search Radius</p>
              <p>Choose a Category</p>
              <p>Pick Your Favorite Business</p>
            </p>
            <div className="circle-1 absolute shadow-lg mx-3 my-3 py-3 rounded">
              <img className="arrow" src="/images/arrow.png" alt="arrow" />
            </div>
          </div>
          <div className="lg:w-1/4 shadow-lg relative mx-3 my-3 py-3 rounded bg-white text-left">
            <img
              className="mx-auto icon my-3 px-4"
              src="/images/browse-menu.svg"
              alt=""
            />
            <h5 className="py-3 px-5 font-semibold text-center">
              Browse Menu & Order
            </h5>
            <p className="py-3 px-5 text-center">
              <p>Browse Menu & Place Order</p>
              <p>Contact & Get Directions</p>
              <p>Statisfaction Survey</p>
            </p>
            <div className="circle-2 absolute shadow-lg mx-3 my-3 py-3 rounded">
              <img className="arrow" src="/images/arrow.png" alt="arrow" />
            </div>
          </div>
          <div className="lg:w-1/4 shadow-lg relative mx-3 my-3 py-3 rounded bg-white text-left">
            <img
              className="mx-auto icon my-3"
              src="/images/order-confirmed.svg"
              alt=""
            />
            <h5 className="py-3 px-5 font-semibold text-center">
              Track Your Order
            </h5>
            <p className="py-3 px-5 text-center">
              <p>Follow Your Order</p>
              <p>From Start to Finish</p>
              <p>
                Even Receive a Photo or Video Notification for Your Peace of
                Mind
              </p>
            </p>
          </div>
        </div>
        <div className="purple-line"><br/></div>
        <div className="text-center py-5 text-3xl lg:text-4xl font-sans font-semibold">
          <h2>How It Works</h2>
        </div>
        <div className="how-it-works mx-auto">
          {/* <img className="mx-auto my-3 px-3" src="/images/video_dummy.svg" alt=""/> */}
          <div className="videoWrapper">
            <ReactPlayer
              // playing
              controls
              url="https://djd6xj8apm2ir.cloudfront.net/Order+Tracker+Intro+May+1st+v3.mp4"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="w-full text-center py-5 text-3xl lg:text-4xl font-sans font-semibold">
          <h2>Everyone Benefits Using The Free Order Tracker</h2>
        </div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center">
          <div className="lg:w-1/2 shadow-lg mx-3 my-3 rounded bg-white py-3">
            <img
              className="mx-auto icon my-3"
              src="/images/owners.svg"
              alt=""
            />
            <h3 className="text-3xl my-3 font-sans font-semibold text-black">
              Business Owners
            </h3>
            <ul className="business_benefits">
              <li>Free Business From Order Tracker</li>
              <li>Free Potential Customer Monitoring</li>
              <li>Real Time Orders</li>
              <li>Easy to Use with Just a Few Clicks</li>
            </ul>
          </div>
          <div className="lg:w-1/2 shadow-lg mx-3 my-3 rounded bg-white py-3">
            <img
              className="mx-auto icon my-3 px-5"
              src="/images/app-users.svg"
              alt=""
            />
            <h3 className="text-3xl my-3 font-sans font-semibold text-black">
              App Users
            </h3>
            <ul className="user_benefits">
              <li>
                <strong>Convenient:</strong> See All Participating Businesses
                within a 50km Radius, View Menus & Place Orders
              </li>
              <li>
                <strong>Safety Assurance:</strong> Due to the Coronavirus
                Request a Photo or Video Notification of Your Order
              </li>
              <li>
                <strong>Status:</strong> Follow Your Order in Real Time From the
                Moment it's Received
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full text-center py-5 text-3xl lg:text-4xl font-sans font-semibold">
          <h2>Business Owners View</h2>
        </div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center business-owners">
          <div className="lg:w-3/4 mx-3 my-3 py-3">
            <img src="/images/potential-ss.svg" alt=""/>
          </div>
          <div className="lg:w-1/4 mx-3 my-3 py-3">
            <h3>Potential Customers</h3>
            <p>You will be able to see when a Potential Customer Clicks on Your Business</p>
          </div>
        </div>
        <div className="purple-line-five"><br/></div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center business-owners">
          <div className="lg:w-1/4 mx-3 my-3 py-3 last">
          <h3>Received Orders</h3>
          <p>You will Click Order Received and the App User will be Notified</p>
          </div>
          <div className="lg:w-3/4 mx-3 my-3 py-3 first">
            <img src="/images/received-ss.svg" alt=""/>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center business-owners">
          <div className="lg:w-3/4 mx-3 my-3 py-3">
            <img src="/images/started-ss.svg" alt=""/>
          </div>
          <div className="lg:w-1/4 mx-3 my-3 py-3">
            <h3>Order Started</h3>
            <p>When starting the Order you will Click Order Started and the App User will be Notified</p>
          </div>
        </div>
        <div className="purple-line-six"><br/></div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center business-owners">
          <div className="lg:w-1/4 mx-3 my-3 py-3 last">
            <h3>Ready in 5 minutes</h3>
            <p>Click this when Order is almost ready and the App User will be Notified</p>
          </div>
          <div className="lg:w-3/4 mx-3 my-3 py-3 first">
            <img src="/images/ready-in-ss.svg" alt=""/>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center business-owners">
          <div className="lg:w-3/4 mx-3 my-3 py-3">
            <img src="/images/pickup-ss.svg" alt=""/>
          </div>
          <div className="lg:w-1/4 mx-3 my-3 py-3">
            <h3>Ready for Pickup</h3>
            <p>Click Ready for Pickup and the Customer will be Notified and should be there already or arriving shortly</p>
          </div>
        </div>
        <div className="purple-line-seven"><br/></div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center business-owners">
          <div className="lg:w-1/4 mx-3 my-3 py-3 last">
            <h3>Out for Delivery</h3>
            <p>Click Out for Delivery after the Driver has picked up the Order and the App User will be Notified</p>
          </div>
          <div className="lg:w-3/4 mx-3 my-3 py-3 first">
            <img src="/images/out-for-delivery.svg" alt=""/>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row container mx-auto text-center business-owners">
          <div className="lg:w-3/4 mx-3 my-3 py-3">
            <img src="/images/complete-ss.svg" alt=""/>
          </div>
          <div className="lg:w-1/4 mx-3 my-3 py-3">
            <h3>Completed Orders</h3>
            <p>Then Click when Order is Completed </p>
          </div>
        </div>
        <div className="purple-line-eight"><br/></div>
        <div className="app__screenshots w-full text-center py-5 text-3xl lg:text-4xl font-sans font-semibold container mx-auto hidden lg:block md:block">
          <h2>App Users View</h2>
          <ul className="my-3">
            <li>
              <img
                className="mx-auto my-3 rounded"
                src="/images/S_1.png"
                alt=""
              />
            </li>
            <li>
              <img
                className="mx-auto my-3 rounded"
                src="/images/S_2.png"
                alt=""
              />
            </li>
            <li>
              <img
                className="mx-auto my-3 rounded"
                src="/images/S_3.png"
                alt=""
              />
            </li>
            <li>
              <img
                className="mx-auto my-3 rounded slider-5"
                src="/images/slider-5.svg"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="block lg:hidden md:hidden app__screenshots__mobile carousel relative shadow-2xl bg-white">
        <div className="carousel-inner relative overflow-hidden w-full">
          <input
            className="carousel-open"
            type="radio"
            id="carousel-1"
            name="carousel"
            aria-hidden="true"
            hidden=""
            checked="checked"
          />
          <div className="carousel-item absolute opacity-0">
            <div className="block h-full w-full text-white text-5xl text-center">
              <img
                className="mx-auto my-3 rounded"
                src="/images/S_1.png"
                alt=""
              />
            </div>
          </div>
          <label
            for="carousel-4"
            className="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >
            ‹
          </label>
          <label
            for="carousel-2"
            className="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >
            ›
          </label>

          <input
            className="carousel-open"
            type="radio"
            id="carousel-2"
            name="carousel"
            aria-hidden="true"
            hidden=""
          />
          <div className="carousel-item absolute opacity-0">
            <div className="block h-full w-full text-white text-5xl text-center">
              <img
                className="mx-auto my-3 rounded"
                src="/images/S_2.png"
                alt=""
              />
            </div>
          </div>
          <label
            for="carousel-1"
            className="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >
            ‹
          </label>
          <label
            for="carousel-3"
            className="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >
            ›
          </label>

          <input
            className="carousel-open"
            type="radio"
            id="carousel-3"
            name="carousel"
            aria-hidden="true"
            hidden=""
          />
          <div className="carousel-item absolute opacity-0">
            <div className="block h-full w-full text-white text-5xl text-center">
              <img
                className="mx-auto my-3 rounded"
                src="/images/S_3.png"
                alt=""
              />
            </div>
          </div>
          <label
            for="carousel-2"
            className="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >
            ‹
          </label>
          <label
            for="carousel-4"
            className="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >
            ›
          </label>

          <input
            className="carousel-open"
            type="radio"
            id="carousel-4"
            name="carousel"
            aria-hidden="true"
            hidden=""
          />
          <div className="carousel-item absolute opacity-0">
            <div className="block h-full w-full text-white text-5xl text-center">
              <img
                className="mx-auto my-3 rounded slider-5"
                src="/images/slider-5.svg"
                alt=""
              />
            </div>
          </div>
          <label
            for="carousel-3"
            className="prev control-4 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >
            ‹
          </label>
          <label
            for="carousel-1"
            className="next control-4 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >
            ›
          </label>

          <ol className="carousel-indicators">
            <li className="inline-block mr-3">
              <label
                for="carousel-1"
                className="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >
                •
              </label>
            </li>
            <li className="inline-block mr-3">
              <label
                for="carousel-2"
                className="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >
                •
              </label>
            </li>
            <li className="inline-block mr-3">
              <label
                for="carousel-3"
                className="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >
                •
              </label>
            </li>
            <li className="inline-block mr-3">
              <label
                for="carousel-4"
                className="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >
                •
              </label>
            </li>
          </ol>
        </div>
      </div>
      <div className="purple-line-four"><br/></div>
      <div className="footer">
        <p className="copyright">Designed and Developed by Apptech Gateway</p>
      </div>
    </>
  );
}

export default LandingPage;
