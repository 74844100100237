/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import './Login.css';
import { LoginFormData } from '../../types';
import { setLoadingText } from '../../lib/actions/appActions';
interface LoginProps {
  handleLogin: (data: LoginFormData) => void;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { register, handleSubmit, errors } = useForm<LoginFormData>();

  const dispatch = useDispatch();

  dispatch(setLoadingText('Logging in ...'));

  const { handleLogin } = props;

  const redirectToRegistration = () => {
    window.location.href =
      'https://admin.apptechgateway.com/apptech-admin/accountRegister';
  };

  return (
    <>
      <div className="login-cont">
        <h1 className="login-title">Never lose track of your order</h1>
        <div className="login-inner">
          {/* <img className="login-phone" src="/images/Phones.png" /> */}
          <div className="login">
            <a href="/login" className="back">
              {' '}
              Back to login{' '}
            </a>

            <h1 className="text-2xl font-semibold"> Reset your password </h1>
            <form onSubmit={handleSubmit(handleLogin)} className="mt-5">
              <div className="mb-3">
                <label className="text-xs text-left text-gray-700 tracking-wide block">
                  Email Address *
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="bg-gray-200 py-1 px-2 mt-1 text-xl rounded w-full"
                  ref={register({ required: true })}
                />
                {errors.username && (
                  <span className="required-field">
                    This field is required.
                  </span>
                )}
              </div>
              <button type="submit"> Submit </button>
            </form>

            <div className="login-extra">
              <small>
                {' '}
                <a href="/forgot"> Forgot Password </a>{' '}
              </small>
            </div>
          </div>
        </div>
        {/* <button className="login-btn">Login Account</button> */}

        <p className="copyright">
          {' '}
          Designed and Developed by{' '}
          <a href="https://apptechgatewaymarketing.com/">
            Apptech Gateway
          </a>{' '}
        </p>
      </div>
    </>
  );
};

export default Login;
