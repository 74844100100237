import { omit } from 'ramda';
import {
  ADD_ORDERS_LIST,
  UPDATE_USER,
  SELECT_ORDER,
  SELECT_TAB,
  UPDATE_SPECIFIC_LIST,
  UPDATE_SEARCH_TEXT,
  UPDATE_ORDER_REQUEST_READ_COUNT,
  REMOVE_NOTIF_COUNT,
  UPDATE_BRANCH_ID,
  ADD_NEW_ORDER_TO_LIST,
  UPDATE_ORDER_TO_ANOTHER_STATE,
  UPDATE_BRANCH_DETAILS,
  DELETE_ORDER,
  ADD_BULK_DELETE_ORDERS_LIST,
  REMOVE_BULK_DELETE_ORDERS_LIST,
  ADD_BULK_LIST_ORDERS,
  TOGGLE_SELECT_ALL_POTENTIAL_ORDERS,
  REMOVE_BULK_LIST_ORDERS,
  DELETE_ORDERS,
  ADD_TOAST_ID,
  REMOVE_TOAST_ID,
  SIDEBAR_OPEN,
  UPDATE_ARCHIVE_ORDERS,
  ADD_BULK_ARCHIVE_ORDERS_LIST,
  REMOVE_BULK_ARCHIVE_ORDERS_LIST,
  TOGGLE_SELECT_ALL_COMPLETED_ORDERS,
} from '../actions/actionsTypes';
import { OrderStatus } from '../order-status';
import removeBulkDeleteOrdersList from './dashboard/remove-bulk-delete-orders-list';
import removeBulkListOrders from './dashboard/remove-bulk-list-orders';
import deleteOrders from './dashboard/delete-orders';

const initialState = {
  orderLists: [],
  potentialOrders: [],
  receivedOrders: [],
  startedOrders: [],
  almostReadyOrders: [],
  pickupOrders: [],
  deliveryOrders: [],
  completeOrders: [],
  archiveOrders: [],
  user: { username: 'DK' },
  selectedOrder: {},
  selectedTab: '',
  searchText: '',
  receivedOrdersCount: 0,
  startedOrdersCount: 0,
  almostReadyOrdersCount: 0,
  pickupOrdersCount: 0,
  deliveryOrdersCount: 0,
  completeOrdersCount: 0,
  branchId: 0,
  branchName: '',
  branchAddress: '',
  branchEmail: '',
  branchContactNumber: '',
  branchUserFirstName: '',
  bulkDeleteOrderList: [],
  bulkArchiveOrderList: [],
  isSelectingAllPotentialOrders: false,
  isSelectingAllCompletedOrders: false,
  orderToast: {},
  isOpen: false,
};

const getCurrentOrderStatusKey = (status) => {
  switch (status) {
    case 'POTENTIAL_ORDER':
      return 'potentialOrders';
    case 'ORDER_RECEIVED':
      return 'receivedOrders';
    case 'ORDER_STARTED':
      return 'startedOrders';
    case 'ORDER_READY_IN_FIVE_MINUTES':
      return 'almostReadyOrders';
    case 'ORDER_READY_FOR_PICKUP':
      return 'pickupOrders';
    case 'ORDER_OUT_FOR_DELIVERY':
      return 'deliveryOrders';
    case 'ORDER_COMPLETE':
      return 'completeOrders';
    default:
      return 'potentialOrders';
  }
};

const removeSpecificOrderFromList = (state, specificOrder) => {
  const originalOrders = [...state.orderLists] as any;

  const currentOrderIndex = originalOrders.findIndex(
    (order: any) => order.orderId === specificOrder.orderId,
  );
  originalOrders.splice(currentOrderIndex, 1);

  return originalOrders;
};

const updateOrderList = (state, orders, firstOrder?: any) => {
  const allOrders = [...orders];

  const results = {
    ...state,
    orderLists: allOrders,
    potentialOrders: allOrders.filter(
      (order: any) => order.orderStatus === OrderStatus.POTENTIAL_ORDER,
    ),
    receivedOrders: allOrders.filter(
      (order: any) => order.orderStatus === OrderStatus.ORDER_RECEIVED,
    ),
    startedOrders: allOrders.filter(
      (order: any) => order.orderStatus === OrderStatus.ORDER_STARTED,
    ),
    almostReadyOrders: allOrders.filter(
      (order: any) =>
        order.orderStatus === OrderStatus.ORDER_READY_IN_FIVE_MINUTES,
    ),
    pickupOrders: allOrders.filter(
      (order: any) => order.orderStatus === OrderStatus.ORDER_READY_FOR_PICKUP,
    ),
    deliveryOrders: allOrders.filter(
      (order: any) => order.orderStatus === OrderStatus.ORDER_OUT_FOR_DELIVERY,
    ),
    completeOrders: allOrders.filter(
      (order: any) => order.orderStatus === OrderStatus.ORDER_COMPLETE,
    ),
  };

  if (firstOrder) {
    const firstOrderType = firstOrder.orderStatus;
    let filteredOrders = [...results[getCurrentOrderStatusKey(firstOrderType)]];
    const newOrderIndex = filteredOrders.findIndex(
      (order: any) => order.orderId === firstOrder.orderId,
    );
    filteredOrders.splice(newOrderIndex, 1);
    filteredOrders = [firstOrder, ...filteredOrders];
    results[getCurrentOrderStatusKey(firstOrderType)] = filteredOrders;
    results.selectedOrder = firstOrder;
  }

  return results;
};

export const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_ORDERS_LIST: {
      return updateOrderList(state, action.payload);
    }
    case SELECT_ORDER: {
      return { ...state, selectedOrder: action.selectedOrder };
    }
    case SELECT_TAB: {
      return { ...state, selectedTab: action.selectedTab };
    }
    case UPDATE_USER: {
      return { ...state, user: action.payload };
    }

    case UPDATE_SPECIFIC_LIST: {
      return { ...state, [action.listType]: action.orders };
    }
    case UPDATE_SEARCH_TEXT: {
      return { ...state, searchText: action.searchText };
    }
    case UPDATE_BRANCH_ID: {
      return { ...state, branchId: action.branchId };
    }
    case DELETE_ORDER: {
      const allOrders = removeSpecificOrderFromList(state, action.order);
      return updateOrderList(state, allOrders);
    }
    case DELETE_ORDERS: {
      return deleteOrders(state, action, updateOrderList);
    }
    case ADD_NEW_ORDER_TO_LIST: {
      const originalOrders = [action.order, ...state.orderLists];
      return updateOrderList(state, originalOrders);
    }
    case UPDATE_BRANCH_DETAILS: {
      const {
        branchName,
        branchAddress,
        branchEmail,
        branchContactNumber,
        branchUserFirstName,
      } = action;
      return {
        ...state,
        branchName,
        branchAddress,
        branchEmail,
        branchContactNumber,
        branchUserFirstName,
      };
    }
    case UPDATE_ORDER_TO_ANOTHER_STATE: {
      const originalOrders = [...state.orderLists] as any;

      const currentOrder = originalOrders.find(
        (order: any) => order.orderId === action.order.orderId,
      ) as any;

      const newOrder = {
        ...currentOrder,
        orderStatus: action.orderStatus,
      };
      const currentOrderIndex = originalOrders.findIndex(
        (order: any) => order.orderId === action.order.orderId,
      );
      originalOrders.splice(currentOrderIndex, 1, newOrder);
      return updateOrderList(state, [...originalOrders], newOrder);
    }
    case UPDATE_ORDER_REQUEST_READ_COUNT: {
      return {
        ...state,
        receivedOrdersCount: action.receivedOrdersCount,
        startedOrdersCount: action.startedOrdersCount,
        almostReadyOrdersCount: action.almostReadyOrdersCount,
        pickupOrdersCount: action.pickupOrdersCount,
        deliveryOrdersCount: action.deliveryOrdersCount,
        completeOrdersCount: action.completeOrdersCount,
      };
    }

    case REMOVE_NOTIF_COUNT: {
      return { ...state, [action.countType]: 0 };
    }

    case ADD_BULK_DELETE_ORDERS_LIST: {
      return {
        ...state,
        bulkDeleteOrderList: [...state.bulkDeleteOrderList, action.orderId],
      };
    }

    case ADD_BULK_LIST_ORDERS: {
      return {
        ...state,
        bulkDeleteOrderList: [...state.bulkDeleteOrderList, ...action.orderIds],
      };
    }

    case ADD_BULK_ARCHIVE_ORDERS_LIST: {
      return {
        ...state,
        bulkArchiveOrderList: [
          ...state.bulkArchiveOrderList,
          ...action.orderIds,
        ],
      };
    }

    case REMOVE_BULK_ARCHIVE_ORDERS_LIST: {
      return {
        ...state,
        bulkArchiveOrderList: [],
      };
    }

    case TOGGLE_SELECT_ALL_COMPLETED_ORDERS: {
      return { ...state, isSelectingAllCompletedOrders: action.value };
    }

    case TOGGLE_SELECT_ALL_POTENTIAL_ORDERS: {
      return { ...state, isSelectingAllPotentialOrders: action.value };
    }

    case REMOVE_BULK_LIST_ORDERS: {
      return removeBulkListOrders(state);
    }

    case REMOVE_BULK_DELETE_ORDERS_LIST: {
      return removeBulkDeleteOrdersList(state, action);
    }

    case ADD_TOAST_ID: {
      const orderToast = {
        ...state.orderToast,
        [action.orderId]: action.toastId,
      };
      return {
        ...state,
        orderToast,
      };
    }

    case REMOVE_TOAST_ID: {
      const orderToast = omit([action.orderId], {
        ...state.orderToast,
      });
      return { ...state, orderToast };
    }

    case SIDEBAR_OPEN: {
      return {
        ...state,
        isOpen: action.isOpen,
      };
    }
    case UPDATE_ARCHIVE_ORDERS: {
      return {
        ...state,
        archiveOrders: action.orders,
      };
    }

    default:
      return { ...state };
  }
};
