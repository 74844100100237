import React, { useState } from 'react';

function MasterlistInfo() {
  const [isOpen, setIsOpen] = useState(false);

  const getHeaders = () => (
    <>
      <small>Branch Location</small>
      <small>City </small>
      <small>State</small>
      <small> Total Orders </small>
    </>
  );

  return (
    <section>
      <div className="breadcrumbs">
        <a href="/dashboard">Masterlist</a>
        <span> Masterlist Info </span>
      </div>
      <div className="table-info">
        <img
          src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/1017px-Starbucks_Corporation_Logo_2011.svg.png"
          alt=""
        />
        <div>
          <h3>
            Starbucks Coffee
            <span> by Jerry Baldwin </span>
          </h3>
        </div>
      </div>
      <div className="list-allbranches">
        <div className="list list-branches">{getHeaders()}</div>
        <div className="list list-branches">
          <p> 28 Germantown Pike Northtowne Plaza </p>
          <p> Euless </p>
          <p> TX </p>
          <p> 241 </p>
        </div>
      </div>
      <div className="table list-content">
        <div className="table">
          <div className="list">
            <small>Current Status</small>
            <small>Customer</small>
            <small>Time Placed</small>
            <small>Previous Status</small>
            <small>Request</small>
            <small>Request Process</small>
            <small>Time / Sender name</small>
          </div>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
      </div>
    </section>
  );
}

export default MasterlistInfo;
