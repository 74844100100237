import React from 'react';
import { Link } from 'react-router-dom';
// import './Unauthorized.scss';

const Unauthorized = () => {
  return (
    <div className="container">
      <div className="message">
        <h1>403 - Unauthorized</h1>
      </div>
      <p>
        <Link to="/">Back to login</Link>
      </p>
    </div>
  );
};

export default Unauthorized;
