import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { getMapsStyle } from '../lib/get-maps-style';

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD912YOKmwQevCUki9Zd_9XJfV4Tmg8vy0',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 36.1174891, lng: -115.2014623 },
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  // console.log('props here', props);
  // console.log(parseFloat(props.lat));
  // console.log(parseFloat(props.long));
  const coordinates = {
    lat: parseFloat(props.lat),
    lng: parseFloat(props.long),
  };
  const center = (props.lat && props.long && coordinates) || props.center;
  return (
    <GoogleMap
      zoom={14}
      center={center}
      // defaultOptions={{ styles: getMapsStyle() }}
      disableDefaultUI={true}
    >
      {props.isMarkerShown && <Marker position={coordinates} />}
    </GoogleMap>
  );
});

export default MyMapComponent;
