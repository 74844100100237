import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { toast } from 'react-toastify';
import {
  getUpdateOrderApiUrl,
  getDeleteOrdersByManagerUrl,
} from '../../../lib/api';
import {
  updateOrderToAnotherState,
  selectOrder,
  deleteOrder,
  removeToastId,
  sidebarOpen,
} from '../../../lib/actions/dashboardActions';
import { setLoading, setLoadingText } from '../../../lib/actions/appActions';
import {
  getOrderButtonClass,
  getOrderStatusImage,
} from '../../../lib/get-order-status';
import { db } from '../../../config/firebase-config';
import { OrderStatus } from '../../../lib/order-status';

function SelectedOrder({ moveTab }) {
  let listenOrderTimer;
  const { selectedTab, orderToast } = useSelector(
    (state: any) => state.dashboard,
  );

  const [orderReadyInFiveTimer, setOrderReadyInFiveTimer] = useState('5:00');
  const [orderReadyForPickupTimer, setOrderReadyForPickupTimer] = useState(
    '5:00',
  );
  const [orderOutForDeliveryTimer, setOrderOutForDeliveryTimer] = useState(
    '5:00',
  );
  const alert = useAlert();
  const selectedOrder = useSelector(
    (state: any) => state.dashboard.selectedOrder,
  );
  const dispatch = useDispatch();
  const noSelectedOrder = Object.keys(selectedOrder).length ? false : true;

  const isSidebarOpen = useSelector((state: any) => state.dashboard.isOpen);

  const listenOrderTimerStatus = () => {
    listenOrderTimer = db
      .doc(`orders/${selectedOrder.orderId}`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const timerData = snapshot.data() || {};
          // console.log('timer data here', timerData);
          if (Object.keys(timerData).length) {
            if (timerData.orderReadyForPickup !== '0:0') {
              setOrderReadyForPickupTimer(
                timerData.orderReadyForPickup || '5:00',
              );
            } else {
              setOrderReadyForPickupTimer(timerData.orderReadyForPickup);
            }
            if (timerData.orderReadyInFive !== '0:0') {
              setOrderReadyInFiveTimer(timerData.orderReadyInFive || '5:00');
            } else {
              setOrderReadyInFiveTimer(timerData.orderReadyInFive);
            }
            if (timerData.orderOutForDelivery !== '0:0') {
              setOrderOutForDeliveryTimer(
                timerData.orderOutForDelivery || '5:00',
              );
            } else {
              setOrderOutForDeliveryTimer(timerData.orderOutForDelivery);
            }
          }
        } else {
          setOrderReadyForPickupTimer('0:0');
          setOrderReadyInFiveTimer('0:0');
          setOrderOutForDeliveryTimer('0:0');
        }
      });
  };

  const updateOrderStatus = (status: string) => {
    if (!Object.keys(selectedOrder).length) {
      alert.info('Please select an order');
      return;
    }

    dispatch(setLoadingText('Processing order ...'));
    dispatch(setLoading(true));
    dispatch(sidebarOpen(false));

    const body = JSON.stringify({
      ...selectedOrder,
      orderStatus: status,
      lastUpdated: Math.round(new Date().getTime() / 1000),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timestamp: {
        ...selectedOrder.timestamp,
        [status]: Math.round(new Date().getTime() / 1000),
      },
    });

    // console.log('selectedOrder here in updateOrderStatus', selectedOrder);
    // console.log('body here in update api', JSON.parse(body));

    fetch(getUpdateOrderApiUrl(), {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
        'Content-Type': 'application/json',
      },
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log('res here', res);
        moveTab(status);
        dispatch(updateOrderToAnotherState(res.order, res.order.orderStatus));
        dispatch(selectOrder(res.order));
        dispatch(setLoading(false));

        // TODO: check if status is ORDER_RECEIVED then dismiss toast of that ID.
        if (status === 'ORDER_RECEIVED') {
          toast.dismiss(orderToast[selectedOrder.orderId]);
          dispatch(removeToastId(selectedOrder.orderId));
        }
      })
      .catch((error) => {
        console.log('error here');
        console.log(error);
      });
  };

  const deleteOrderByManager = () => {
    dispatch(setLoadingText('Deleting order ...'));
    dispatch(setLoading(true));

    const body = JSON.stringify({
      orderIds: [selectedOrder.orderId],
    });

    fetch(getDeleteOrdersByManagerUrl(), {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
        'Content-Type': 'application/json',
      },
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log('res here', res);
        // moveTab(status);
        // dispatch(
        //   updateOrderToAnotherState(selectedOrder, res.order.orderStatus),
        // );
        // console.log('delete res here', res);
        dispatch(deleteOrder(selectedOrder));
        dispatch(selectOrder({}));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        console.log('error here');
        console.log(error);
      });
  };

  const closeSidebar = () => {
    dispatch(sidebarOpen(false));
  };

  // useEffect(() => {
  //   listenOrderTimerStatus();
  //   return () => {
  //     listenOrderTimer();
  //   };
  // }, [selectedOrder]);

  return (
    <aside className={`sidebar ${isSidebarOpen ? '-open' : ''}`}>
      <i className="ion ion-ios-close-circle" onClick={closeSidebar}></i>
      <div className="customer">
        <div className="profile">
          <img
            src={selectedOrder.profileImageUrl || 'images/user.svg'}
            alt={selectedOrder.displayName || 'User'}
            className="image"
          />
          <p className="name">
            {selectedOrder.displayName || 'User'}
            <span className="address">
              {' '}
              {selectedOrder.location || 'Unknown Location'}{' '}
            </span>
            <span className="address">{selectedOrder.phoneNumber}</span>
          </p>
        </div>
        <p className="info">
          {' '}
          Time Order Received:{' '}
          {moment
            .unix(selectedOrder.orderPlaceTimestamp)
            .format('HH:mm:ss')}{' '}
        </p>
      </div>
      <div className="process">
        {!noSelectedOrder && (
          <>
            <div
              role="button"
              tabIndex={0}
              onKeyPress={() => updateOrderStatus('ORDER_RECEIVED')}
              className={`list ${getOrderButtonClass(selectedOrder, 1)} `}
              onClick={() => updateOrderStatus('ORDER_RECEIVED')}
            >
              <label> Click when order is received. </label>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyPress={() => updateOrderStatus('ORDER_STARTED')}
              className={`list ${getOrderButtonClass(selectedOrder, 2)} `}
              onClick={() => updateOrderStatus('ORDER_STARTED')}
            >
              <label> Click when order is started. </label>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyPress={() =>
                updateOrderStatus('ORDER_READY_IN_FIVE_MINUTES')
              }
              className={`list ${getOrderButtonClass(selectedOrder, 3)} `}
              onClick={() => updateOrderStatus('ORDER_READY_IN_FIVE_MINUTES')}
            >
              <label> Click when order is ready in 5 minutes. </label>
            </div>
            {selectedOrder.orderStatus !== 'ORDER_OUT_FOR_DELIVERY' && (
              <div
                role="button"
                tabIndex={0}
                onKeyPress={() => updateOrderStatus('ORDER_READY_FOR_PICKUP')}
                className={`list ${getOrderButtonClass(selectedOrder, 4)} `}
                onClick={() => updateOrderStatus('ORDER_READY_FOR_PICKUP')}
              >
                <label> Click when order is ready for pickup </label>
              </div>
            )}

            {selectedOrder.orderStatus !== 'ORDER_READY_FOR_PICKUP' && (
              <div
                role="button"
                tabIndex={0}
                onKeyPress={() => updateOrderStatus('ORDER_OUT_FOR_DELIVERY')}
                className={`list ${getOrderButtonClass(selectedOrder, 5)} `}
                onClick={() => updateOrderStatus('ORDER_OUT_FOR_DELIVERY')}
              >
                <label> Click when order is out for delivery </label>
              </div>
            )}

            <div
              role="button"
              tabIndex={0}
              onKeyPress={() => updateOrderStatus('ORDER_COMPLETE')}
              className={`list ${getOrderButtonClass(selectedOrder, 6)} `}
              onClick={() => updateOrderStatus('ORDER_COMPLETE')}
            >
              <label> Click when order is complete </label>
            </div>
          </>
        )}
      </div>

      {!noSelectedOrder && (
        <img
          src={`images/${getOrderStatusImage(selectedOrder.orderStatus)}.svg`}
          alt=""
          className={`graphic ${getOrderStatusImage(
            selectedOrder.orderStatus,
          )}`}
        />
      )}

      {/* {selectedTab === 'POTENTIAL_ORDER' && !noSelectedOrder && (
        <button
          type="button"
          className="btn-remove"
          onClick={deleteOrderByManager}
        >
          Remove Order
        </button>
      )} */}
    </aside>
  );
}

export default SelectedOrder;
