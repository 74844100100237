import { SET_LOADING, SET_LOADING_TEXT } from './actionsTypes';

export const setLoading = (isLoading) => {
  return {
    type: SET_LOADING,
    isLoading,
  };
};

export const setLoadingText = (loadingText) => {
  return {
    type: SET_LOADING_TEXT,
    loadingText,
  };
};
