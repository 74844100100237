import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectOrder,
  sidebarOpen,
} from '../../../lib/actions/dashboardActions';
import Orderlist from './Orderlist';
import GoogleMapComponent from '../../../components/GoogleMapComponent';

function AccessLogs() {
  return (
    <section>
      <div className="table">
        <div className="list">
          <small>Current Status</small>
          <small>Customer</small>
          <small>Time Placed</small>
          <small>Previous Status</small>
          <small>Request</small>
          <small>Request Process</small>
          <small>Time / Sender name</small>
        </div>
      </div>
      <div className="table list-content">
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
        <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div>
      </div>
    </section>
  );
}

export default AccessLogs;
