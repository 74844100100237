import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateSearchText,
  updateSpecificList,
  updateOrderList,
} from '../../../lib/actions/dashboardActions';

function SearchBar() {
  const dispatch = useDispatch();
  const {
    searchText,
    orderLists,
    selectedTab,
    potentialOrders,
    receivedOrders,
    startedOrders,
    almostReadyOrders,
    pickupOrders,
    deliveryOrders,
    completeOrders,
    receivedOrdersCount,
    startedOrdersCount,
    almostReadyOrdersCount,
    pickupOrdersCount,
    deliveryOrdersCount,
    completeOrdersCount,
  } = useSelector((state: any) => state.dashboard);

  const getCurrentOrderList = (tab?: string) => {
    switch (tab || selectedTab) {
      case 'POTENTIAL_ORDER':
        return [potentialOrders, 'potentialOrders'];
      case 'ORDER_RECEIVED':
        return [receivedOrders, 'receivedOrders', receivedOrdersCount];
      case 'ORDER_STARTED':
        return [startedOrders, 'startedOrders', startedOrdersCount];
      case 'ORDER_READY_IN_FIVE_MINUTES':
        return [almostReadyOrders, 'almostReadyOrders', almostReadyOrdersCount];
      case 'ORDER_READY_FOR_PICKUP':
        return [pickupOrders, 'pickupOrders', pickupOrdersCount];
      case 'ORDER_OUT_FOR_DELIVERY':
        return [deliveryOrders, 'deliveryOrders', deliveryOrdersCount];
      case 'ORDER_COMPLETE':
        return [completeOrders, 'completeOrders', completeOrdersCount];
      default:
        return [potentialOrders, 'potentialOrders'];
    }
  };

  const onChangeSearch = (event: any) => {
    dispatch(updateSearchText(String(event.target.value)));
    const currentSearchText = String(event.target.value).toLowerCase();

    if (currentSearchText !== '') {
      const [, currentOrderListType] = getCurrentOrderList();

      if (['photo', 'video'].includes(currentSearchText)) {
        const searchList = orderLists.filter(
          (order: any) =>
            order.requestType !== 'NONE' &&
            order.requestType.toLowerCase() === currentSearchText &&
            order.orderStatus === selectedTab,
        );
        dispatch(updateSpecificList(searchList, currentOrderListType));
      } else {
        const searchList = orderLists.filter(
          (order: any) =>
            order.phoneNumber.includes(currentSearchText) ||
            (order.displayName.toLowerCase().includes(currentSearchText) &&
              order.orderStatus === selectedTab),
        );
        dispatch(updateSpecificList(searchList, currentOrderListType));
      }
    } else {
      dispatch(updateOrderList(orderLists));
    }
  };

  return (
    <div>
      <form action="" className="form">
        <label className="label"> Search </label>
        <input
          className="search"
          type="search"
          value={searchText}
          onChange={onChangeSearch}
        />
      </form>
    </div>
  );
}

export default SearchBar;
