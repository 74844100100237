export const getOrderApiUrl = (userId: string) =>
  `https://hqg5dc3pli.execute-api.us-east-1.amazonaws.com/dev/order/branch?userId=${userId}`;

export const getArchiveOrderApiUrl = (userId: string) =>
  `https://hqg5dc3pli.execute-api.us-east-1.amazonaws.com/dev/order/archive?userId=${userId}`;

export const getUpdateOrderApiUrl = () =>
  `https://hqg5dc3pli.execute-api.us-east-1.amazonaws.com/dev/order/update`;

export const getUpdateReadRequestUrl = () =>
  `https://hqg5dc3pli.execute-api.us-east-1.amazonaws.com/dev/order/update-request-state`;

export const getDeleteOrdersByManagerUrl = () =>
  `https://hqg5dc3pli.execute-api.us-east-1.amazonaws.com/dev/order/delete/manager`;

export const getArchiveOrdersByManagerUrl = () =>
  `https://hqg5dc3pli.execute-api.us-east-1.amazonaws.com/dev/order/archive`;
