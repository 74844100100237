import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateArchiveOrdersList } from '../../../lib/actions/dashboardActions';
import Orderlist from './Orderlist';
import { getArchiveOrderApiUrl } from '../../../lib/api';

function ArchiveList({ userId }) {
  const dispatch = useDispatch();

  const { archiveOrders } = useSelector((state: any) => state.dashboard);

  const fetchArhiveOrders = () => {
    fetch(getArchiveOrderApiUrl(userId), {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(updateArchiveOrdersList(response));
      });
  };

  useEffect(() => {
    fetchArhiveOrders();
  }, [userId]);

  return (
    <section>
      <div className="table">
        <div className="list">
          <small>Current Status</small>
          <small>Customer</small>
          <small>Time Placed</small>
          <small>Previous Status</small>
          <small>Request</small>
          <small>Request Process</small>
          <small>Time / Sender name</small>
        </div>
      </div>
      <div className="table list-content">
        {/* Sample */}
        {/* <div className="list">
          <p className="status">
            <img src="images/delivery-icon.svg" alt="" />
            Order received
          </p>
          <p>Yeshua</p>
          <p>Apr-17-2020 18:16:15</p>
          <p>Order received</p>
          <p> VIDEO </p>
          <p> Not yet done</p>
          <p> &nbsp; </p>
        </div> */}

        <Orderlist
          orders={archiveOrders}
          clickSelectedOrder={() => {}}
          selectedOrder={() => {}}
          isArchived
        />
      </div>
    </section>
  );
}

export default ArchiveList;
