import {
  CognitoUserPool,
  ICognitoUserPoolData,
  CognitoUser,
} from 'amazon-cognito-identity-js';

export const poolConfig: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID as string, // Your user pool id here
  ClientId: process.env.REACT_APP_APP_CLIENT_ID as string,
};

export const userPool = new CognitoUserPool(poolConfig);

export const getCognitoUser = (username: string) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return cognitoUser;
};
