/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import './Login.css';
import { LoginFormData } from '../../types';
import { setLoadingText, setLoading } from '../../lib/actions/appActions';
import { getCognitoUser } from '../../config/cognito-pool';

interface LoginProps {
  handleLogin: (data: LoginFormData) => void;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const alert = useAlert();
  const { register, handleSubmit, errors, reset } = useForm<LoginFormData>();

  const [
    isDoneRequestingForgotPassword,
    setIsDoneRequestingForgotPassword,
  ] = useState(false);

  const [username, setUsername] = useState('');

  const dispatch = useDispatch();

  const setBeforeActions = (isDoneProcessingPassword?: boolean) => {
    dispatch(setLoadingText('Loading ...'));
    dispatch(setLoading(true));
    setIsDoneRequestingForgotPassword(isDoneProcessingPassword || false);
  };

  const setAfterActions = (isDoneProcessingPassword?: boolean) => {
    setIsDoneRequestingForgotPassword(isDoneProcessingPassword || true);
    dispatch(setLoading(false));
    reset();
  };

  const doneActions = () => {
    dispatch(setLoading(false));
    reset();
  };

  const forgotPassword = (formData: LoginFormData): void => {
    setBeforeActions();
    console.log('forgotPassword', formData);

    if (formData.username) {
      setUsername(formData.username);
      const user = getCognitoUser(formData.username);
      user.forgotPassword({
        onSuccess: (data) => {
          console.log(data);
          console.log(
            `CodeDeliveryData from forgotPassword: ${JSON.stringify(data)}`,
          );
          setAfterActions();
        },
        onFailure: (error) => {
          setUsername('');
          console.log(error.message || JSON.stringify(error));
          setAfterActions();
        },
      });
    }
  };

  const confirmPassword = (formData: LoginFormData) => {
    setBeforeActions(true);
    console.log('confirmPassword here');
    console.log(formData);
    if (formData.code && formData.newPassword) {
      const user = getCognitoUser(username);

      const { code, newPassword } = formData;
      user.confirmPassword(code, newPassword, {
        onSuccess: () => {
          console.log('Password confirmed!');
          setIsDoneRequestingForgotPassword(false);

          doneActions();
          alert.success('Password successfully changed.');
        },
        onFailure: (error) => {
          console.log('Password not confirmed!');
          console.log(error);
          alert.error(error.message);
          setIsDoneRequestingForgotPassword(true);

          doneActions();
        },
      });
    }
  };

  return (
    <>
      <div className="login-cont">
        <h1 className="login-title">Never lose track of your order</h1>
        <div className="login-inner">
          {/* <img className="login-phone" src="/images/Phones.png" /> */}
          <div className="login">
            <a href="/login" className="back">
              {' '}
              Back to login{' '}
            </a>

            <h1 className="text-2xl font-semibold"> Forgot password </h1>

            {(!isDoneRequestingForgotPassword && (
              <form onSubmit={handleSubmit(forgotPassword)} className="mt-5">
                <div className="mb-3">
                  <label className="text-xs text-left text-gray-700 tracking-wide block">
                    Username *
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    className="bg-gray-200 py-1 px-2 mt-1 text-xl rounded w-full"
                    ref={register({ required: true })}
                  />
                  {errors.username && (
                    <span className="required-field">
                      This field is required.
                    </span>
                  )}
                </div>
                <button type="submit"> Submit </button>
              </form>
            )) || (
              <form onSubmit={handleSubmit(confirmPassword)} className="mt-5">
                <div className="mb-3">
                  <label className="text-xs text-left text-gray-700 tracking-wide block">
                    Verification Code *
                  </label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    className="bg-gray-200 py-1 px-2 mt-1 text-xl rounded w-full"
                    ref={register({ required: true })}
                  />
                  <span>
                    Please check your email for the verification code.
                  </span>

                  {errors.code && (
                    <span className="required-field">
                      This field is required.
                    </span>
                  )}

                  <label className="text-xs text-left text-gray-700 tracking-wide block">
                    New Password *
                  </label>

                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    className="bg-gray-200 py-1 px-2 mt-1 text-xl rounded w-full"
                    ref={register({ required: true })}
                  />
                  {errors.newPassword && (
                    <span className="required-field">
                      This field is required.
                    </span>
                  )}
                </div>
                <button type="submit"> Submit </button>
              </form>
            )}

            {/* <div className="login-extra">
              <small>
                {' '}
                <a href="/reset"> Reset Password </a>{' '}
              </small>
            </div> */}
          </div>
        </div>
        {/* <button className="login-btn">Login Account</button> */}

        <p className="copyright">
          {' '}
          Designed and Developed by{' '}
          <a href="https://apptechgatewaymarketing.com/">
            Apptech Gateway
          </a>{' '}
        </p>
      </div>
    </>
  );
};

export default Login;
