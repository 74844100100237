import React, { useState } from 'react';
import moment from 'moment';
import { path, prop } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import {
  getOrderRowCss,
  getOrderStatus,
  getPreviosOrderStatus,
  getOrderSvg,
} from '../../../lib/get-order-status';
import {
  addBulkDeleteOrders,
  removeBulkDeleteOrders,
  toggleSelectAllOrders,
  addBulkListOrders,
  removeBulkListOrders,
  selectOrder,
  deleteOrders,
  addBulkArchiveListOrders,
  removeBulkArchiveListOrders,
  toggleSelectAllCompletedOrders,
} from '../../../lib/actions/dashboardActions';
import { setLoading, setLoadingText } from '../../../lib/actions/appActions';
import {
  getDeleteOrdersByManagerUrl,
  getArchiveOrdersByManagerUrl,
} from '../../../lib/api';

export default ({
  orders,
  clickSelectedOrder,
  selectedOrder,
  isArchived = false,
}) => {
  const {
    potentialOrders,
    completeOrders,
    bulkDeleteOrderList,
    bulkArchiveOrderList,
    selectedTab,
    isSelectingAllPotentialOrders,
    isSelectingAllCompletedOrders,
  } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const hasNotificationDetails = (order) =>
    order &&
    order.notificationDetails &&
    Object.keys(order.notificationDetails).length;

  const isPotentialOrder = selectedTab === 'POTENTIAL_ORDER';
  const isOrderComplete = selectedTab === 'ORDER_COMPLETE';

  const [showDelete, setShowDelete] = useState(false);
  const [showArchive, setShowArchive] = useState(false);

  const onChangeDeleteCheckbox = (event) => {
    const { id, checked } = event.target;

    if (checked) dispatch(addBulkDeleteOrders(id));
    else dispatch(removeBulkDeleteOrders(id));
  };

  const onChangeArchiveCheckbox = (event) => {
    const { id, checked } = event.target;
    if (checked) dispatch(addBulkArchiveListOrders([id]));
    else dispatch(removeBulkArchiveListOrders([id]));
  };

  const deletBulkOrders = (orderIds, isSelectingAll) => {
    console.log('delete here');
    console.log('order ids here', orderIds);

    dispatch(setLoadingText('Deleting order ...'));
    dispatch(setLoading(true));

    const body = JSON.stringify({
      orderIds,
    });

    console.log('body here in delete order', body);

    fetch(getDeleteOrdersByManagerUrl(), {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        'Content-Type': 'application/json',
      },
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log('res here', res);
        dispatch(deleteOrders(orderIds));
        dispatch(toggleSelectAllOrders(!isSelectingAll));
        dispatch(selectOrder({}));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        console.log('error here');
        console.log(error);
      });
  };

  const deletBulkArchiveOrders = (orderIds, isSelectingAll) => {
    dispatch(setLoadingText('Archiving order ...'));
    dispatch(setLoading(true));

    const body = JSON.stringify({
      orderIds,
    });

    fetch(getArchiveOrdersByManagerUrl(), {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        'Content-Type': 'application/json',
      },
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log('res here', res);
        dispatch(deleteOrders(orderIds));
        dispatch(toggleSelectAllOrders(!isSelectingAll));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        console.log('error here');
        console.log(error);
      });
  };

  const isOrderChecked = (orderIds, orderId) => {
    return orderIds.indexOf(orderId) !== -1;
  };

  const selectAllPotentialOrders = (isSelectingAll, selectedAllOrders) => {
    dispatch(toggleSelectAllOrders(!isSelectingAll));
    const orderIds = selectedAllOrders.map((order) => prop('orderId')(order));
    const currentValue = !isSelectingAll;
    if (currentValue) {
      dispatch(addBulkListOrders(orderIds));
    } else {
      dispatch(removeBulkListOrders(orderIds));
    }
  };

  const selectAllCompletedOrders = (isSelectingAll, selectedAllOrders) => {
    dispatch(toggleSelectAllCompletedOrders(!isSelectingAll));
    const orderIds = selectedAllOrders.map((order) => prop('orderId')(order));
    const currentValue = !isSelectingAll;
    if (currentValue) {
      dispatch(addBulkArchiveListOrders(orderIds));
    } else {
      dispatch(removeBulkArchiveListOrders(orderIds));
    }
  };

  return (
    <>
      {potentialOrders.length !== 0 && isPotentialOrder && (
        <div className="list-actions">
          <button
            type="button"
            title="Select all"
            onClick={() =>
              selectAllPotentialOrders(
                isSelectingAllPotentialOrders,
                potentialOrders,
              )
            }
          >
            {(isSelectingAllPotentialOrders && (
              <i class="ion ion-md-checkmark-circle"></i>
            )) || <i class="ion ion-md-checkmark-circle-outline"></i>}
          </button>
          <button
            type="button"
            title="Delete"
            className="delete"
            onClick={() =>
              deletBulkOrders(
                bulkDeleteOrderList,
                isSelectingAllPotentialOrders,
              )
            }
          >
            <i class="ion ion-md-close-circle"></i>
          </button>
        </div>
      )}

      {completeOrders.length !== 0 && isOrderComplete && (
        <div className="list-actions">
          <button
            type="button"
            title="Select all"
            onClick={() =>
              selectAllCompletedOrders(
                isSelectingAllCompletedOrders,
                completeOrders,
              )
            }
          >
            {(isSelectingAllCompletedOrders && (
              <i class="ion ion-md-checkmark-circle"></i>
            )) || <i class="ion ion-md-checkmark-circle-outline"></i>}
          </button>
          <button
            type="button"
            title="Delete"
            className="delete"
            onClick={() =>
              deletBulkArchiveOrders(
                bulkArchiveOrderList,
                isSelectingAllCompletedOrders,
              )
            }
          >
            <i class="ion ion-md-filing"></i>
          </button>
        </div>
      )}
      {(orders.length &&
        orders.map((order) => (
          <>
            <div
              key={order.orderId}
              role="button"
              tabIndex={0}
              onKeyPress={() => clickSelectedOrder(order)}
              className={`list ${getOrderRowCss(order.orderStatus)} ${
                selectedOrder.orderId === order.orderId && '-active'
              }`}
              onClick={() => clickSelectedOrder(order)}
            >
              <p className="status">
                <img src={getOrderSvg(order.orderStatus)} alt="" />
                {getOrderStatus(order.orderStatus)}
              </p>
              <p>{order.displayName}</p>
              <p>
                {moment
                  .unix(order.orderPlaceTimestamp)
                  .format('MMM-DD-YYYY HH:mm:ss')}
              </p>
              <p>
                {getPreviosOrderStatus ? (
                  getPreviosOrderStatus(order.orderStatus)
                ) : (
                  <span>-</span>
                )}
              </p>
              <p>
                {' '}
                {order.requestType !== 'NONE' ? (
                  order.requestType
                ) : (
                  <span>-</span>
                )}{' '}
              </p>
              <p>
                {order.requestType !== 'NONE' ? (
                  <>
                    {order.isDoneProcessingRequest ? (
                      <>
                        {' '}
                        <img src="images/check-icon.svg"></img> Done{' '}
                      </>
                    ) : (
                      'Not yet done'
                    )}
                  </>
                ) : (
                  <span>-</span>
                )}
              </p>

              <p>
                {' '}
                {hasNotificationDetails(order) ? (
                  path(['notificationDetails', 'senderName'])(order)
                ) : (
                  <span>-</span>
                )}{' '}
              </p>

              {isPotentialOrder && (
                <p style={{ textAlign: 'center', padding: '0' }}>
                  <input
                    type="checkbox"
                    id={order.orderId}
                    onChange={onChangeDeleteCheckbox}
                    checked={isOrderChecked(bulkDeleteOrderList, order.orderId)}
                  />
                </p>
              )}

              {!isArchived && isOrderComplete && (
                <p style={{ textAlign: 'center', padding: '0' }}>
                  <input
                    type="checkbox"
                    id={order.orderId}
                    onChange={onChangeArchiveCheckbox}
                    checked={isOrderChecked(
                      bulkArchiveOrderList,
                      order.orderId,
                    )}
                  />
                </p>
              )}
            </div>
          </>
        ))) || <div className="no-orders">No orders yet.</div>}
    </>
  );
};
