import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SearchBar from './SearchBar';

function BranchUserInfo({ handleLogout }) {
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const { branchUserFirstName, branchName, branchAddress } = useSelector(
    (state: any) => state.dashboard,
  );

  const openProfile = () => {
    setIsProfileOpen(!isProfileOpen);
    console.log(isProfileOpen);
  };

  return (
    <>
      {/* <button className="intro-mobile" onClick={openProfile}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 18H11M4 6H20H4ZM4 12H20H4Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button> */}
      <header className={`intro ${isProfileOpen ? '-open' : ''}`}>
        <div className="user">
          <h1 className="title"> Welcome, {branchUserFirstName || 'User'}</h1>
          <p> {branchName || 'Branch name'}</p>
          <p>
            {' '}
            {(branchAddress &&
              `${branchAddress.city} ${branchAddress.state} ${branchAddress.addressLine1}`) ||
              'Branch Address'}
          </p>
          <p>
            {' '}
            <button onClick={handleLogout} type="button" className="btn-logout">
              {' '}
              Logout{' '}
            </button>{' '}
          </p>
        </div>
        {/* <SearchBar /> */}
      </header>
    </>
  );
}

export default BranchUserInfo;
