import { SET_LOADING, SET_LOADING_TEXT } from '../actions/actionsTypes';

const initialState = {
  isLoading: false,
  loadingText: 'Logging in ...',
};

export const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_TEXT: {
      return { ...state, loadingText: action.loadingText };
    }
    case SET_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    default:
      return { ...state };
  }
};
