import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { useAlert } from 'react-alert';
import Login from './Login/Login';
import Reset from './Login/Reset';
import Forgot from './Login/Forgot';
import Dashboard from './Dashboard/Dashboard';
import LandingPage from './LandingPage/LandingPage';
import ProtectedRoute from '../components/ProtectedRoute';
import { LoginFormData } from '../types';
import Unauthorized from '../components/Unauthorized/Unauthorized';
import { userPool } from '../config/cognito-pool';
import { setUser } from '../lib/actions/dashboardActions';
import { setLoading, setLoadingText } from '../lib/actions/appActions';

type AppProps = RouteComponentProps;

const App: React.FC<AppProps> = (props: AppProps) => {
  const alert = useAlert();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const loading = useSelector((state: any) => state.app.isLoading);
  const loadingText = useSelector((state: any) => state.app.loadingText);
  const dispatch = useDispatch();

  // const [loadingText, setLoadingText] = useState('Logging in ...');

  const handleLogin = (data: LoginFormData): void => {
    dispatch(setLoading(true));
    const authenticationData = {
      Username: data.username,
      Password: data.password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: data.username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession) => {
        dispatch(setUser(session.getIdToken().payload));
        setIsUserLoggedIn(true);
        dispatch(setLoading(false));
        localStorage.setItem('sub', session.getIdToken().payload.sub as string);
        localStorage.setItem(
          'username',
          session.getIdToken().payload['cognito:username'],
        );
        localStorage.setItem(
          'payload',
          JSON.stringify(session.getIdToken().payload),
        );
        props.history.push('/dashboard');
      },
      onFailure: (error) => {
        console.log('ERROR HERE');
        console.log(error);
        alert.error(error.message);
        dispatch(setLoading(false));
      },
    });
  };

  const handleLogout = () => {
    setIsUserLoggedIn(false);
    localStorage.removeItem('sub');
    props.history.replace('/login');
  };

  return (
    <>
      <LoadingOverlay text={loadingText} spinner active={loading}>
        <div>
          <Switch>
            <Route
              path="/login"
              exact
              render={(props: any) => <Login handleLogin={handleLogin} />}
            />
            <Route
              path="/reset"
              exact
              render={(props: any) => <Reset handleLogin={handleLogin} />}
            />
            <Route
              path="/forgot"
              exact
              render={(props: any) => <Forgot handleLogin={handleLogin} />}
            />
            <Route path="/" exact render={(props: any) => <LandingPage />} />
            <ProtectedRoute
              exact
              isUserLoggedIn={isUserLoggedIn}
              path="/dashboard"
              component={Dashboard}
              handleLogout={handleLogout}
            />
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route exact path="/unauthorized" component={Unauthorized} />
          </Switch>
        </div>
        <ToastContainer autoClose={false} />
      </LoadingOverlay>
    </>
  );
};

export default withRouter(App);
