import {
  UPDATE_USER,
  ADD_ORDERS_LIST,
  SELECT_ORDER,
  SELECT_TAB,
  UPDATE_SPECIFIC_LIST,
  UPDATE_SEARCH_TEXT,
  UPDATE_ORDER_REQUEST_READ_COUNT,
  REMOVE_NOTIF_COUNT,
  UPDATE_BRANCH_ID,
  ADD_NEW_ORDER_TO_LIST,
  UPDATE_ORDER_TO_ANOTHER_STATE,
  UPDATE_BRANCH_DETAILS,
  DELETE_ORDER,
  ADD_BULK_DELETE_ORDERS_LIST,
  REMOVE_BULK_DELETE_ORDERS_LIST,
  ADD_BULK_LIST_ORDERS,
  REMOVE_BULK_LIST_ORDERS,
  TOGGLE_SELECT_ALL_POTENTIAL_ORDERS,
  DELETE_ORDERS,
  ADD_TOAST_ID,
  REMOVE_TOAST_ID,
  SIDEBAR_OPEN,
  UPDATE_ARCHIVE_ORDERS,
  ADD_BULK_ARCHIVE_ORDERS_LIST,
  REMOVE_BULK_ARCHIVE_ORDERS_LIST,
  TOGGLE_SELECT_ALL_COMPLETED_ORDERS,
} from './actionsTypes';

export const setUser = (userPayload) => {
  return {
    type: UPDATE_USER,
    payload: userPayload,
  };
};

export const updateOrderList = (orders) => {
  return {
    type: ADD_ORDERS_LIST,
    payload: orders,
  };
};

export const selectOrder = (selectedOrder) => {
  return {
    type: SELECT_ORDER,
    selectedOrder,
  };
};

export const selectTab = (selectedTab) => {
  return {
    type: SELECT_TAB,
    selectedTab,
  };
};

export const updateSearchText = (searchText) => {
  return {
    type: UPDATE_SEARCH_TEXT,
    searchText,
  };
};

export const updateSpecificList = (orders, type) => {
  return {
    type: UPDATE_SPECIFIC_LIST,
    orders,
    listType: type,
  };
};

export const updateOrderRequestReadCount = (params) => {
  const {
    receivedOrdersCount,
    startedOrdersCount,
    almostReadyOrdersCount,
    pickupOrdersCount,
    deliveryOrdersCount,
    completeOrdersCount,
  } = params;
  return {
    type: UPDATE_ORDER_REQUEST_READ_COUNT,
    receivedOrdersCount,
    startedOrdersCount,
    almostReadyOrdersCount,
    pickupOrdersCount,
    deliveryOrdersCount,
    completeOrdersCount,
  };
};

export const removeNotifCount = (countType) => {
  return {
    type: REMOVE_NOTIF_COUNT,
    countType,
  };
};

export const updateBranchId = (branchId) => {
  return {
    type: UPDATE_BRANCH_ID,
    branchId,
  };
};

export const addNewOrderToList = (order) => {
  return {
    type: ADD_NEW_ORDER_TO_LIST,
    order,
  };
};

export const deleteOrder = (order) => {
  return {
    type: DELETE_ORDER,
    order,
  };
};

export const deleteOrders = (orders) => {
  return {
    type: DELETE_ORDERS,
    orders,
  };
};

export const updateOrderToAnotherState = (order, orderStatus) => {
  return {
    type: UPDATE_ORDER_TO_ANOTHER_STATE,
    order,
    orderStatus,
  };
};

export const updateBranchDetails = ({
  branchName,
  branchAddress,
  branchContactNumber,
  branchEmail,
  branchUserFirstName,
}) => {
  return {
    type: UPDATE_BRANCH_DETAILS,
    branchName,
    branchAddress,
    branchContactNumber,
    branchEmail,
    branchUserFirstName,
  };
};

export const addBulkDeleteOrders = (orderId) => ({
  type: ADD_BULK_DELETE_ORDERS_LIST,
  orderId,
});

export const removeBulkDeleteOrders = (orderId) => ({
  type: REMOVE_BULK_DELETE_ORDERS_LIST,
  orderId,
});

export const addBulkListOrders = (orderIds) => ({
  type: ADD_BULK_LIST_ORDERS,
  orderIds,
});

export const removeBulkListOrders = (orderIds) => ({
  type: REMOVE_BULK_LIST_ORDERS,
  orderIds,
});

export const toggleSelectAllOrders = (value) => ({
  type: TOGGLE_SELECT_ALL_POTENTIAL_ORDERS,
  value,
});

export const addBulkArchiveListOrders = (orderIds) => ({
  type: ADD_BULK_ARCHIVE_ORDERS_LIST,
  orderIds,
});

export const removeBulkArchiveListOrders = (orderIds) => ({
  type: REMOVE_BULK_ARCHIVE_ORDERS_LIST,
  orderIds,
});

export const toggleSelectAllCompletedOrders = (value) => ({
  type: TOGGLE_SELECT_ALL_COMPLETED_ORDERS,
  value,
});

export const addToastId = (toastId, orderId) => ({
  type: ADD_TOAST_ID,
  toastId,
  orderId,
});

export const removeToastId = (orderId) => ({
  type: REMOVE_TOAST_ID,
  orderId,
});

export const sidebarOpen = (isOpen) => {
  return {
    type: SIDEBAR_OPEN,
    isOpen,
  };
};

export const updateArchiveOrdersList = (orders) => {
  return {
    type: UPDATE_ARCHIVE_ORDERS,
    orders,
  };
};
