import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectOrder,
  sidebarOpen,
} from '../../../lib/actions/dashboardActions';
import Orderlist from './Orderlist';
import GoogleMapComponent from '../../../components/GoogleMapComponent';

function MainList() {
  const {
    selectedTab,
    potentialOrders,
    selectedOrder,
    receivedOrders,
    startedOrders,
    almostReadyOrders,
    pickupOrders,
    deliveryOrders,
    completeOrders,
  } = useSelector((state: any) => state.dashboard);
  const dispatch = useDispatch();

  const clickSelectedOrder = (order: any) => {
    dispatch(selectOrder(order));
    dispatch(sidebarOpen(true));
    // listenOrderTimerStatus(order);
  };

  const getHeaders = () => (
    <>
      <small>Current Status</small>
      <small>Customer</small>
      <small>Time Placed</small>
      <small>Previous Status</small>
      <small>Request</small>
      <small>Request Process</small>
      <small>Time / Sender name</small>
      {selectedTab === 'POTENTIAL_ORDER' && <small></small>}
    </>
  );
  return (
    <div className="list">
      <div className="data">
        <div data-tabs-content>
          <div
            data-tabs-pane
            className={`tabs-pane ${
              selectedTab === 'POTENTIAL_ORDER' ? 'active' : ''
            } `}
            id="potential"
          >
            <div className="table">
              <div className="list">{getHeaders()}</div>
              <Orderlist
                orders={potentialOrders}
                clickSelectedOrder={clickSelectedOrder}
                selectedOrder={selectedOrder}
              />
            </div>
          </div>
          <div
            data-tabs-pane
            className={`tabs-pane ${
              selectedTab === 'ORDER_RECEIVED' ? 'active' : ''
            } `}
            id="received"
          >
            <div className="table">
              <div className="list">{getHeaders()}</div>
              <Orderlist
                orders={receivedOrders}
                clickSelectedOrder={clickSelectedOrder}
                selectedOrder={selectedOrder}
              />
            </div>
          </div>
          <div
            data-tabs-pane
            className={`tabs-pane ${
              selectedTab === 'ORDER_STARTED' ? 'active' : ''
            } `}
            id="started"
          >
            <div className="table">
              <div className="list">{getHeaders()}</div>
              <Orderlist
                orders={startedOrders}
                clickSelectedOrder={clickSelectedOrder}
                selectedOrder={selectedOrder}
              />
            </div>
          </div>
          <div
            data-tabs-pane
            className={`tabs-pane ${
              selectedTab === 'ORDER_READY_IN_FIVE_MINUTES' ? 'active' : ''
            } `}
            id="ready"
          >
            <div className="table">
              <div className="list">{getHeaders()}</div>
              <Orderlist
                orders={almostReadyOrders}
                clickSelectedOrder={clickSelectedOrder}
                selectedOrder={selectedOrder}
              />
            </div>
          </div>
          <div
            data-tabs-pane
            className={`tabs-pane ${
              selectedTab === 'ORDER_READY_FOR_PICKUP' ? 'active' : ''
            } `}
            id="pickup"
          >
            <div className="table">
              <div className="list">{getHeaders()}</div>
              <Orderlist
                orders={pickupOrders}
                clickSelectedOrder={clickSelectedOrder}
                selectedOrder={selectedOrder}
              />
            </div>
          </div>

          <div
            data-tabs-pane
            className={`tabs-pane ${
              selectedTab === 'ORDER_OUT_FOR_DELIVERY' ? 'active' : ''
            } `}
            id="delivery"
          >
            <div className="table">
              <div className="list">{getHeaders()}</div>
              <Orderlist
                orders={deliveryOrders}
                clickSelectedOrder={clickSelectedOrder}
                selectedOrder={selectedOrder}
              />
            </div>
          </div>

          <div
            data-tabs-pane
            className={`tabs-pane ${
              selectedTab === 'ORDER_COMPLETE' ? 'active' : ''
            } `}
            id="complete"
          >
            <div className="table">
              <div className="list">{getHeaders()}</div>
              <Orderlist
                orders={completeOrders}
                clickSelectedOrder={clickSelectedOrder}
                selectedOrder={selectedOrder}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="map">
        <GoogleMapComponent
          isMarkerShown={Object.keys(selectedOrder).length}
          lat={parseFloat(selectedOrder.lat)}
          long={parseFloat(selectedOrder.long)}
        />
      </div>
    </div>
  );
}

export default MainList;
