import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getOrderApiUrl, getArchiveOrderApiUrl } from '../../lib/api';
import {
  updateOrderList,
  selectOrder,
  selectTab,
  updateSearchText,
  updateOrderRequestReadCount,
  updateBranchId,
  addNewOrderToList,
  updateBranchDetails,
  addToastId,
  updateArchiveOrdersList,
} from '../../lib/actions/dashboardActions';
import './Dashboard.css';
import { pusher } from '../../lib/pusher';
import SelectedOrder from './components/SelectedOrder';
import OrderTabs from './components/OrderTabs';
import MainList from './components/MainList';
import Masterlist from './components/Masterlist';
import ArchiveList from './components/ArchiveList';
import MasterlistInfo from './components/MasterlistInfo';
import BranchUserInfo from './components/BranchUserInfo';
import SearchBar from './components/SearchBar';
import AccessLogs from './components/AccessLogs';

interface IProps {
  handleLogout: () => void;
}

type DashboardProps = IProps & RouteComponentProps;

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  const dashboardState = useSelector((state: any) => state.dashboard);
  const branchId = useSelector((state: any) => state.dashboard.branchId);

  const dispatch = useDispatch();
  const userId = dashboardState.user.sub || localStorage.getItem('sub');
  const { orderLists, selectedOrder } = dashboardState;

  const { handleLogout } = props;

  const fetchOrders = () => {
    fetch(getOrderApiUrl(userId), {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(updateOrderList(response.orders));
        dispatch(
          updateBranchId(
            response.orders.length ? response.orders[0].branchId : 0,
          ),
        );
        dispatch(
          updateBranchDetails({
            branchName: response.branchName,
            branchAddress: response.branchAddress,
            branchContactNumber: response.branchContactNumber,
            branchEmail: response.branchEmail,
            branchUserFirstName: response.branchUserFirstName,
          }),
        );
        dispatch(
          updateOrderRequestReadCount({
            receivedOrdersCount: response.receivedOrdersCount,
            startedOrdersCount: response.startedOrdersCount,
            almostReadyOrdersCount: response.almostReadyOrdersCount,
            pickupOrdersCount: response.pickupOrdersCount,
            deliveryOrdersCount: response.deliveryOrdersCount,
            completeOrdersCount: response.completeOrdersCount,
          }),
        );

        if (Object.keys(selectedOrder).length) {
          const newSelectedOrder = response.find(
            (order: any) => order.orderId === selectedOrder.orderId,
          );
          dispatch(selectOrder(newSelectedOrder));
        }
      })
      .catch((error) => {
        /* eslint-disable */
        console.log('ERROR HERE IN FETCH API CALL');
        console.log(error);
        /* eslint-enable */
      });
  };

  const fetchArhiveOrders = () => {
    fetch(getArchiveOrderApiUrl(userId), {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(updateArchiveOrdersList(response));
      });
  };

  const getBranchId = () => {
    return orderLists.length ? orderLists[0].branchId : 0;
  };

  const moveTab = (status) => {
    dispatch(selectTab(status));
    switch (status) {
      case 'POTENTIAL_ORDER':
        props.history.push('#potential');
        break;
      case 'ORDER_STARTED':
        props.history.push('#started');
        break;
      case 'ORDER_RECEIVED':
        props.history.push('#received');
        break;
      case 'ORDER_READY_IN_FIVE_MINUTES':
        props.history.push('#ready');
        break;
      case 'ORDER_READY_FOR_PICKUP':
        props.history.push('#pickup');
        break;
      case 'ORDER_OUT_FOR_DELIVERY':
        props.history.push('#delivery');
        break;
      case 'ORDER_COMPLETE':
        props.history.push('#complete');
        break;
      default:
        props.history.push('#potential');
    }
  };

  const showNewMediaRequestToast = (order: any, callback: any) => {
    const requestType = order.requestType.toLowerCase();
    // requestType === 'photo'
    //   ? document.body.className.replace('video', 'photo')
    //   : document.body.className.replace('photo', 'video');
    // document.body.classList.add(requestType);
    toast(
      <>
        <div onClick={callback}>
          {' '}
          <img
            src={
              requestType === 'photo'
                ? '/images/image-icon.svg'
                : '/images/video-icon.svg'
            }
          />{' '}
          <span>
            {' '}
            <em> {order.displayName} </em> has requested a {requestType} of his
            order.{' '}
          </span>{' '}
        </div>
      </>,
    );
  };

  const showNewOrderToast = (name: string, callback: any, orderId: any) => {
    const toastId = toast(
      <>
        <div onClick={callback}>
          {' '}
          <img src="/images/bell-icon-2.svg" />{' '}
          <span>
            {' '}
            There is a new order by <em> {name} </em>
          </span>{' '}
        </div>
      </>,
      {
        toastId: orderId,
      },
    );
    dispatch(addToastId(toastId, orderId));
  };

  const listenForPusherMessages = () => {
    setTimeout(() => {
      if (branchId) {
        const channel = pusher.subscribe(`${branchId}-channel`);
        channel.bind('NewOrder', (data: any) => {
          const firstOrder = data.length ? data[0] : {};
          dispatch(addNewOrderToList(firstOrder));
          showNewOrderToast(
            firstOrder.displayName,
            () => {
              dispatch(selectOrder(firstOrder));
              moveTab('POTENTIAL_ORDER');
            },
            firstOrder.orderId,
          );
        });
        channel.bind('NewRequestMedia', (data: any) => {
          const firstOrder = data.length ? data[0] : {};
          showNewMediaRequestToast(firstOrder, () => {
            // dispatch(addNewOrderToList(firstOrder));
            dispatch(selectOrder(firstOrder));
            moveTab(firstOrder.orderStatus);
          });
        });
      }
    }, 2000);
  };

  const orient = () => {
    const orient = window.matchMedia('(orientation: landscape)').matches;
    console.log(orient);
  };

  useEffect(() => {
    fetchOrders();
    orient();

    if (branchId) listenForPusherMessages();
    const interval = setInterval(() => {
      fetchOrders();
    }, 60000);
    return () => clearInterval(interval);
  }, [branchId]);

  const onChangeTab = (tab: string) => {
    dispatch(selectTab(tab));
    dispatch(selectOrder({}));
    dispatch(updateSearchText(''));
    dispatch(updateOrderList(orderLists));
    // showNewMediaRequestToast({ requestType: 'PHOTO' }, null);
  };

  return (
    <>
      <div className="wrapper">
        <section className="data">
          {/* <BranchUserInfo handleLogout={handleLogout} />
          <OrderTabs onChangeTab={onChangeTab} />
          <h6 className="message-notif">
            {' '}
            Allow up to 60 seconds for order to update or{' '}
            <a href="/dashboard">refresh the browser</a>.{' '}
          </h6>
          <MainList /> */}
          <div className="tabs-main">
            <ul className="tabs" data-tabs>
              <li className="user-tab">
                <a data-tab href="#admin" className="active">
                  <img src="images/user.svg" className="image" /> Admin
                </a>
              </li>
              <li className="active">
                <a data-tab href="#orders" className="active">
                  Orders
                </a>
              </li>
              <li style={{ display: 'none' }}>
                <a data-tab href="#masterlist" className="active">
                  Masterlist
                </a>
              </li>
              <li>
                <a data-tab href="#access" className="active">
                  Access Logs
                </a>
              </li>
              <li>
                <a data-tab href="#archive" className="active">
                  Archive
                </a>
              </li>
            </ul>
            <SearchBar />
          </div>

          <div className="data-content" data-tabs-content>
            <div data-tabs-pane className="tabs-pane" id="admin">
              <div className="user">
                <BranchUserInfo handleLogout={handleLogout} />
              </div>
            </div>

            <div data-tabs-pane className="tabs-pane" id="masterlist">
              <MasterlistInfo />
              {/* <Masterlist /> */}
            </div>

            <div data-tabs-pane className="tabs-pane" id="access">
              <AccessLogs />
            </div>

            <div data-tabs-pane className="tabs-pane" id="archive">
              <ArchiveList userId={userId} />
            </div>

            <div data-tabs-pane className="tabs-pane active" id="orders">
              <section>
                <OrderTabs onChangeTab={onChangeTab} />
                <h6 className="message-notif">
                  {' '}
                  Allow up to 60 seconds for order to update or{' '}
                  <a href="/dashboard">refresh the browser</a>.{' '}
                </h6>
                <MainList />
              </section>
            </div>
          </div>
        </section>

        <SelectedOrder moveTab={moveTab} />
      </div>
    </>
  );
};

export default withRouter(Dashboard);
