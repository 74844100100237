/* eslint-disable no-underscore-dangle */
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { reducer } from './reducers';

export const store =
  (process.env.NODE_ENV as string) === 'development'
    ? createStore(reducer, composeWithDevTools())
    : createStore(reducer);
